export const PriceMatrixPropertyTypes = {
  basics: {
    descriptionToken: 'price_matrix.property_type.basics.title',
    properties: [
      {
        id: 'qrCode',
        descriptionToken: 'price_matrix.property_type.basics.property.qr_code.description',
        descriptionTokenShort: 'price_matrix.property_type.basics.property.qr_code.description.short',
        moreInfoToken: 'price_matrix.property_type.basics.property.qr_code.more_info',
      },
      {
        id: 'directLink',
        descriptionToken: 'price_matrix.property_type.basics.property.direct_link.description',
        descriptionTokenShort: 'price_matrix.property_type.basics.property.direct_link.description.short',
        moreInfoToken: 'price_matrix.property_type.basics.property.direct_link.more_info',
      },
      {
        id: 'allDevices',
        descriptionToken: 'price_matrix.property_type.basics.property.all_devices.description',
        descriptionTokenShort: 'price_matrix.property_type.basics.property.all_devices.description.short',
        moreInfoToken: 'price_matrix.property_type.basics.property.all_devices.more_info',
      },
      {
        id: 'safety',
        descriptionToken: 'price_matrix.property_type.basics.property.safety.description',
        moreInfoToken: 'price_matrix.property_type.basics.property.safety.more_info',
      },
      {
        id: 'gallery',
        descriptionToken: 'price_matrix.property_type.basics.property.gallery.description',
        descriptionTokenShort: 'price_matrix.property_type.basics.property.gallery.description.short',
        moreInfoToken: 'price_matrix.property_type.basics.property.gallery.more_info',
      },
      {
        id: 'editor',
        descriptionToken: 'price_matrix.property_type.basics.property.editor.description',
        descriptionTokenShort: 'price_matrix.property_type.basics.property.editor.description.short',
        moreInfoToken: 'price_matrix.property_type.basics.property.editor.more_info',
      },
      {
        id: 'unlimitedUsers',
        descriptionToken: 'price_matrix.property_type.basics.property.unlimited_users.description',
        descriptionTokenShort: 'price_matrix.property_type.basics.property.unlimited_users.description.short',
        moreInfoToken: 'price_matrix.property_type.basics.property.unlimited_users.more_info',
      },
      {
        id: 'durationInMonths',
        descriptionToken: 'price_matrix.property_type.basics.property.duration_in_months.description',
        descriptionTokenShort: 'price_matrix.property_type.basics.property.duration_in_months.description.short',
        moreInfoToken: 'price_matrix.property_type.basics.property.duration_in_months.more_info',
      },
    ],
  },
  hosting: {
    descriptionToken: 'price_matrix.property_type.hosting.title',
    properties: [
      {
        id: 'connector',
        descriptionToken: 'price_matrix.property_type.hosting.property.connector.description',
        moreInfoToken: 'price_matrix.property_type.hosting.property.connector.more_info',
      },
      {
        id: 'speed',
        descriptionToken: 'price_matrix.property_type.hosting.property.speed.description',
        moreInfoToken: 'price_matrix.property_type.hosting.property.speed.more_info',
      },
      {
        id: 'unlimitedData',
        descriptionToken: 'price_matrix.property_type.hosting.property.unlimited_data.description',
        descriptionTokenShort: 'price_matrix.property_type.hosting.property.unlimited_data.description.short',
        moreInfoToken: 'price_matrix.property_type.hosting.property.unlimited_data.more_info',
      },
      {
        id: 'maxVideoSize',
        descriptionToken: 'price_matrix.property_type.hosting.property.max_video_size.description',
        descriptionTokenShort: 'price_matrix.property_type.hosting.property.max_video_size.description.short',
        moreInfoToken: 'price_matrix.property_type.hosting.property.max_video_size.more_info',
      },
    ],
  },
  extras: {
    descriptionToken: 'price_matrix.property_type.extras.title',
    properties: [
      {
        id: 'mediaManagement',
        descriptionToken: 'price_matrix.property_type.extras.property.media_management.description',
        moreInfoToken: 'price_matrix.property_type.extras.property.media_management.more_info',
      },
      {
        id: 'slideshow',
        descriptionToken: 'price_matrix.property_type.extras.property.slideshow.description',
        moreInfoToken: 'price_matrix.property_type.extras.property.slideshow.more_info',
      },
      {
        id: 'manualSorting',
        descriptionToken: 'price_matrix.property_type.extras.property.manual_sorting.description',
        moreInfoToken: 'price_matrix.property_type.extras.property.manual_sorting.more_info',
      },
    ],
  },
} as const
